import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/misc/Layout'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      allPagina_legals(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            titulo
            documento_legal
          }
        }
      }
    }
  }
`

const checkExistDataByPrismic = data => {
  return data && data.prismic && !!data.prismic.allPagina_legals.edges[0]
}

const PageLegal = ({ data }) => {
  if (!checkExistDataByPrismic(data)) return null
  const [prismicContent] = data.prismic.allPagina_legals.edges
  const {
    node: { titulo, documento_legal },
  } = prismicContent
  return (
    <Layout>
      <h1>{titulo[0].text}</h1>
      {documento_legal.map(({ text }) => (
        <p>{text}</p>
      ))}
    </Layout>
  )
}

PageLegal.propTypes = {
  path: PropTypes.string.isRequired,
}

export default PageLegal
